import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const GreenhillLogoLandscape = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "Greenhill-MASTER-Colour-LS.png" }) {
        childImageSharp {
          fluid(maxHeight: 120, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)

  // Note: with no width, this won't display in a flex container
  const props = {
    alt: "Greenhill Psychology logo",
    fadeIn: false,
    loading: "eager",
    fluid: data.image.childImageSharp.fluid,
    style: {
      maxWidth: data.image.childImageSharp.fluid.presentationWidth,
      margin: "0 auto", // used to center the image
    }
  }

  return (
    <Img {...props} />
  )
}

export default GreenhillLogoLandscape
