import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import GreenhillLogoLandscape from "./greenhill-logo-ls"

const Header = ({ location }) => {

  const mobileMenuButton = React.useRef();
  const mobileMenu = React.useRef();

  const handleClick= (e) => {
      if(!mobileMenuButton.current.contains(e.target) && !mobileMenu.current.contains(e.target)) {
        setMenuState({
          ...menuState,
          open: false,
        });
      }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    }
  })

  const getMenuItemClass = name => {
    return location === name
      ? "text-lg bg-primary text-white font-bold cursor-default"
      : "text-lg focus:text-white focus:bg-secondaryGreen hover:text-white hover:bg-secondaryGreen"
  }

  const [menuState, setMenuState] = useState({
    open: false,
  })

  const onKeyPressed = e => {
    if (e.keyCode === 27) {
      setMenuState({
        ...menuState,
        open: false,
      })
    }
  }

  const toggleMenu = e => {
    e.stopPropagation();
    setMenuState({
      ...menuState,
      open: !menuState.open,
    })
  }

  const menuClass = () => {
    return menuState.open 
      ? "mobile-menu" 
      : "mobile-menu closed";
  }

  return (
    <nav onKeyDown={e => onKeyPressed(e)} className="shadow text-primary">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div
              className="flex-shrink-0"
              style={{ minWidth: 280, minHeight: 66 }}
            >
              <Link to="/">
                <GreenhillLogoLandscape />
              </Link>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline">
                <Link
                  to="/"
                  className={
                    getMenuItemClass("home") +
                    " ml-2 px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                  }
                >
                  Home
                </Link>
                <Link
                  to="/services/"
                  className={
                    getMenuItemClass("services") +
                    " ml-2 px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                  }
                  href="#"
                >
                  Services
                </Link>
                <Link
                  to="/appointments/"
                  className={
                    getMenuItemClass("appointments") +
                    " ml-2 px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                  }
                  href="#"
                >
                  Appointments
                </Link>
                <Link
                  to="/about/"
                  className={
                    getMenuItemClass("about") +
                    " ml-2 px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                  }
                  href="#"
                >
                  About
                </Link>
                <Link
                  to="/contact/"
                  className={
                    getMenuItemClass("contact") +
                    " ml-2 px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                  }
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              ref={mobileMenuButton}
              onClick={e => toggleMenu(e)}
              className="inline-flex items-center justify-center p-2 rounded-md text-primary hover:text-white hover:bg-primary focus:outline-none focus:bg-primary focus:text-white"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  className={menuState.open ? "hidden" : "inline-flex"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
                <path
                  className={menuState.open ? "inline-flex" : "hidden"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div ref={mobileMenu} className={menuClass()}>
        <div className="px-2 pt-2 pb-3 sm:px-3">
          <Link
            to="/"
            className={getMenuItemClass("home") + ' block px-3 py-2 rounded-md text-base font-medium focus:outline-none'}
          >
            Home
          </Link>
          <Link
            to="/services/"
            className={getMenuItemClass("services") + ' mt-1 block px-3 py-2 rounded-md text-base font-medium focus:outline-none'}
            href="#"
          >
            Services
          </Link>
          <Link
            to="/appointments/"
            className={getMenuItemClass("appointments") + ' mt-1 block px-3 py-2 rounded-md text-base font-medium focus:outline-none'}
            href="#"
          >
            Appointments
          </Link>
          <Link
            to="/about/"
            className={getMenuItemClass("about") + ' mt-1 block px-3 py-2 rounded-md text-base font-medium focus:outline-none'}
            href="#"
          >
            About
          </Link>
          <Link
            to="/contact/"
            className={getMenuItemClass("contact") + ' mt-1 block px-3 py-2 rounded-md text-base font-medium focus:outline-none'}
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  location: PropTypes.string,
}

Header.defaultProps = {
  location: ``,
}

export default Header
